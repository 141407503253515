// packages
import { Tabs } from "antd"
import styled from "styled-components"

// commons
import { theme } from "@project/common"

export const StyledTabs = styled(Tabs)`
  .ant-tabs-nav {
    margin-bottom: 0;
    @media print {
      display: none;
    }
    .ant-tabs-nav-wrap {
      .ant-tabs-nav-list .ant-tabs-tab {
        position: relative;
        flex: 1;
        max-width: 128px;
        min-width: 128px;
        padding: 12px 8px;
        align-items: center;
        justify-content: center;
        border-radius: 12px 12px 0 0;
        border-color: ${theme.colors.border3};
        border-bottom-color: ${theme.colors.cyan2};
        background-color: ${theme.colors.background};
        .tab-label {
          color: ${theme.colors.neutral2};
        }
        &:not(:first-child) {
          margin-left: 10px;
        }
        .nav-item-icon {
          color: white;
        }
        &.ant-tabs-tab-active {
          color: ${theme.colors.text};
          border-color: ${theme.colors.cyan2};
          background-color: ${theme.colors.white};
          .nav-item-icon {
            color: ${theme.colors["yellow-2"]};
          }
          .tab-label {
            color: ${theme.colors.text};
          }
          &::after {
            width: 100%;
            height: 2px;
            left: 0;
            background-color: ${theme.colors.white};
          }
        }
        .ant-tabs-tab-btn {
          height: 100%;
          span {
            white-space: break-spaces;
          }
        }
        &::after {
          content: "";
          position: absolute;
          bottom: -1px;
          left: -1px;
          width: calc(100% + 2px);
          height: 1px;
          background-color: ${theme.colors.cyan2};
        }

        @media screen and (max-width: ${theme.breakpoints.md}) {
          padding: 6px 10px;
          width: 53.83px;
          min-width: 53.83px;
          &:not(:first-child) {
            margin-left: 4px;
          }
          .ant-tabs-tab-btn .tab-label {
            display: none;
          }
        }
      }
    }

    &::before {
      border-color: ${theme.colors.cyan2};
    }
  }

  .ant-tabs-content-holder {
    border: 1px solid ${theme.colors.cyan2};
    border-top: none;
    background-color: ${theme.colors.white};
    padding: 40px 30px;
    border-radius: 0px 0px 12px 12px;
    @media print {
      padding: 0;
      border: none;
    }

    @media screen and (max-width: ${theme.breakpoints.md}) {
      padding: 16px;
    }
    @media screen and (max-width: ${theme.breakpoints.sm}) {
      padding: 8px;
    }
  }
`
