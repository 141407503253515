import { PaperIcon } from "../../public/assets/icons/navIcons"
import { ConsumerMenuItems } from "./types"

export const consumerMenuItems: ConsumerMenuItems = [
  {
    key: "/",
    link: "/",
    label: "Daily Record",
    icon: <PaperIcon />,
  },
  // {
  //   key: "contact-facility",
  //   link: "/contact-facility",
  //   label: "Contact Facility",
  //   icon: <PencilEditIcon />,
  // },
  // {
  //   key: "daily-record",
  //   link: "/daily-record",
  //   label: "Daily Record",
  //   icon: <ClipBoardIcon />,
  // },
  // {
  //   key: "service-provision",
  //   link: "/service-provision",
  //   label: "Service provision",
  //   icon: <PersonIcon />,
  // },
  // {
  //   key: "email-reception",
  //   link: "/email-reception",
  //   label: "Email reception",
  //   icon: <ClockIcon />,
  // },
  // {
  //   key: "resume",
  //   link: "/resume",
  //   label: "Resume",
  //   icon: <ResumeIcon />,
  // },
  // {
  //   key: "individual-support-plan",
  //   link: "/individual-support-plan",
  //   label: "Individual support plan",
  //   icon: <SupportPlanIcon />,
  // },
  // {
  //   key: "individual-support-plan-draft",
  //   link: "/individual-support-plan-draft",
  //   label: "Individual support plan (draft)",
  //   icon: <SupportPlanV2Icon />,
  // },
  // {
  //   key: "monitoring",
  //   link: "/monitoring",
  //   label: "Monitoring",
  //   icon: <MonitoringIcon />,
  // },
]
