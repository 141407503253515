import React from "react"

export const LockIcon = () => {
  return (
    <svg
      width={"17"}
      height={"24"}
      viewBox={"0 0 17 24"}
      fill={"none"}
      xmlns={"http://www.w3.org/2000/svg"}
    >
      <path
        d={"M16 9.94141H1V22.8434H16V9.94141Z"}
        fill={"#FDD95F"}
        stroke={"#191919"}
        strokeWidth={"1.5"}
        strokeLinejoin={"round"}
      />
      <path
        d={
          "M13.6836 9.94131V6.41518C13.6791 5.19914 13.2616 4.02198 12.5018 3.08265C11.7419 2.14331 10.6863 1.49942 9.51335 1.25978C8.34037 1.02014 7.12195 1.19945 6.06402 1.76741C5.0061 2.33537 4.17353 3.25715 3.70703 4.37696"
        }
        stroke={"#191919"}
        strokeWidth={"1.5"}
        strokeLinecap={"round"}
        strokeLinejoin={"round"}
      />
      <path
        d={"M8.5 15.2207V17.5647"}
        stroke={"#191919"}
        strokeWidth={"1.5"}
        strokeLinecap={"round"}
        strokeLinejoin={"round"}
      />
    </svg>
  )
}
