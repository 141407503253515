import { theme } from "@project/common"
import { Flex, Typography } from "antd"
import React from "react"
import styled from "styled-components"
export const StyledContentWrapper = styled.div`
  border: 1px solid ${theme.colors.border};
  border-radius: 12px;
  @media print {
    border: none;
  }
  .content_header {
    background-color: ${theme.colors.greenish};
    min-height: 51px;
    border-radius: 12px 12px 0 0;
    padding: 0 20px;
    @media print {
      display: none;
    }
    .title {
      margin: 0 !important;
    }
  }
  .content_content {
    padding: 20px 30px;
    @media print {
      padding: 0;
    }
  }
`

export const ContentWrapper = ({
  children,
  title,
  iconURL,
}: {
  children: React.ReactNode
  title: string
  iconURL?: string
}) => {
  return (
    <StyledContentWrapper>
      <Flex className={"content_header"} align={"center"} gap={15}>
        <img
          src={iconURL || "/assets/icons/clipboard_with_bottom_check_mark.svg"}
          alt={"icon"}
        />
        <Typography.Title level={5} className={"title"}>
          {title}
        </Typography.Title>
      </Flex>
      <div className={"content_content"}> {children}</div>
    </StyledContentWrapper>
  )
}
