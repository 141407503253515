// packages
import { Flex, Layout, Popover } from "antd"
import styled from "styled-components"

// commons
import { Box, theme } from "@project/common"

const { Header, Footer, Content } = Layout

export const HeaderLogo = styled(Box)`
  border-bottom-right-radius: 12px;
  .name-text {
    line-height: normal;
    font-size: 16px;
    font-weight: 500;
    color: ${theme.colors.white};
    .sub-text {
      font-weight: normal;
      font-size: inherit;
    }
  }

  .setting-button {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`

export const StyledHeader = styled(Header)`
  position: relative;
  height: 70px;
  background: ${theme.colors.white};
  padding-inline: 0;
  display: flex;
  justify-content: flex-end;
  @media print {
    display: none;
  }
  .setting-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: ${theme.colors.secondary};
    border: none;
    height: 100%;
    width: 80px;
    color: ${theme.colors.white};
    .setting-btn-label {
      font-size: 12px;
    }
    &:hover {
      color: ${theme.colors.accent2};
    }
  }

  @media screen and (max-width: ${theme.breakpoints.md}) {
    height: 72px;
    .setting-button {
      width: 72px;
    }
  }
`

export const TimeTrackBtnGroup = styled(Flex)`
  width: 100%;
  justify-content: center;

  .attendance-btn-box,
  .break-time-btn {
    flex: 1;
  }

  @media screen and (min-width: ${theme.breakpoints.md}) {
    width: auto;
  }
`

export const StyledContent = styled(Content)`
  padding: 70px 85px;
  padding-bottom: 25px;
  background: ${theme.colors.gray8};
  min-height: calc(100vh - 146px);
  @media print {
    padding: 0px;
    background-color: white;
  }
  .attendance-action {
    @media print {
      display: none;
    }

    .attendance-button {
      height: 72px;
      border-radius: 12px;
      box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.25);
      span {
        font-size: 22px;
      }
      @media screen and (max-width: ${theme.breakpoints.md}) {
        height: 58px;
        span {
          font-size: 18px;
        }
      }
    }

    .break-time-btn {
      height: 72px;
      border-radius: 12px;
      box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.15);
      padding-inline: 20px;
      &.btn-tracking {
        border-color: ${theme.colors.red4};
        color: ${theme.colors.red4};
      }
      .btn-content {
        span {
          font-size: 22px;
          font-weight: 500;
        }

        .coffee-icon {
          position: relative;
          &::after {
            display: none;
            position: absolute;
            content: "";
            height: 6px;
            width: 2px;
            background-color: ${theme.colors.red4};
            border-radius: 1px;
            left: 40%;
            top: 0;
          }
          &::before {
            display: none;
            position: absolute;
            content: "";
            height: 4px;
            width: 2px;
            background-color: ${theme.colors.red4};
            border-radius: 1px;
            left: 20%;
            top: 2px;
          }
          .steam {
            display: none;
            position: absolute;
            content: "";
            height: 4px;
            width: 2px;
            background-color: ${theme.colors.red4};
            border-radius: 1px;
            left: 60%;
            top: 0;
          }

          &.tracking {
            &::after,
            &::before,
            .steam {
              display: block;
            }
            &::after {
              animation: steam 1600ms infinite;
            }
            &::before {
              animation: steam 1000ms infinite;
              animation-delay: 200ms;
            }
            .steam {
              animation: steam 1000ms infinite;
              animation-delay: 300ms;
            }
          }

          @keyframes steam {
            from {
              top: 3px;
              opacity: 1;
            }
            to {
              top: -2px;
              opacity: 0;
            }
          }
        }
      }

      @media screen and (max-width: ${theme.breakpoints.md}) {
        height: 58px;
        .btn-content {
          span {
            font-size: 18px;
          }
        }
      }
    }

    .attendance-button:disabled {
      background-color: ${theme.colors.disabled} !important;
      box-shadow: none;
      &:hover {
        filter: brightness(100%) !important;
        background-color: ${theme.colors.disabled} !important;
      }
    }

    .attendance-button.tracking-time {
      background-color: ${theme.colors.red4};
      &:hover {
        background-color: ${theme.colors.red4} !important;
        filter: brightness(110%);
      }
    }

    .attendance-button.tracking-pending,
    .attendance-button.tracking-time-completed {
      background-color: ${theme.colors.green1};
      &:hover {
        background-color: ${theme.colors.green1};
        filter: brightness(110%);
      }
    }

    .time-cycle-text {
      font-size: 24px;
      white-space: nowrap;
      color: ${theme.colors.text};
      @media screen and (max-width: ${theme.breakpoints.md}) {
        font-size: 18px;
      }
    }

    @media screen and (max-width: ${theme.breakpoints.md}) {
      gap: 16px !important;
      width: 100%;
    }

    @media screen and (max-width: ${theme.breakpoints.md}) {
      flex-direction: column;
    }
  }
  .page-title {
    font-size: 20px;
    font-weight: 500;
    color: ${theme.colors.text};
    padding: 30px 0;
    @media print {
      display: none;
    }
  }

  @media screen and (max-width: ${theme.breakpoints.md}) {
    padding: 34px 16px;
    .page-title {
      padding: 24px 0;
    }
  }
  @media screen and (max-width: ${theme.breakpoints.sm}) {
    padding: 25px 8px;
  }
`

export const StyledFooter = styled(Footer)`
  padding: 8px 30px;
  background: ${theme.colors.white};
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  @media print {
    display: none;
  }
  .footer-box__logo {
    .logo-img {
      border: 1px solid ${theme.colors.cyan2} !important;
      border-radius: 50%;
    }
  }

  .ant-divider {
    height: 30px;
    border-inline-start: 1px solid ${theme.colors.border3};
  }

  .footer-right-section {
    .footer-right-section__logo {
      display: none;
    }
  }

  @media screen and (max-width: ${theme.breakpoints.md}) {
    padding: 16px;
    flex-wrap: nowrap;

    .footer-box__logo {
      display: none;
    }

    .footer-extra-info {
      flex-direction: column;
      align-items: flex-start;
      gap: 6px;
      .ant-divider {
        display: none;
      }
      span {
        font-size: 16px;
        color: ${theme.colors.text};
      }
    }

    .footer-right-section {
      .footer-right-section__logo {
        display: block;
        .left-logo-img {
          border: 1px solid ${theme.colors.cyan2} !important;
          border-radius: 50%;
        }
      }
    }
  }
`

export const SettingPopover = styled(Popover)`
  .ant-popover {
    inset: 70px 0px auto auto !important;

    .ant-popover-content .ant-popover-inner {
      border: 1px solid ${theme.colors.secondary};
      padding: 0;
      overflow: hidden;
      border-radius: 8px 0px 0px 8px;
      box-shadow: none;
      .ant-popover-title {
        margin: 0;
      }
      .ant-popover-inner-content .setting-menu {
        border: none;
        .ant-menu-item {
          display: flex;
          align-items: center;
          margin: 0;
          width: 100%;
          border-radius: 0;
          height: 54px;
          gap: 8px;
          padding-inline: 20px;
        }
      }
    }
  }
`

export const BreakTimeFlex = styled(Flex)`
  .label,
  .value {
    font-size: 20px;
    color: ${theme.colors.text};
  }
  .label {
    opacity: 0.8;
  }
`
