import React from "react"
import Image from "next/image"
import styled from "styled-components"
import { theme } from "@project/common"
export const InfoBox = styled.div`
  display: flex;
  padding: 19px 16px;
  border-radius: 8px;
  gap: 12px;
  border: 1px solid ${theme.colors.cyan1};
  background-color: ${theme.colors.cyan3};
  @media print {
    display: none;
  }
  .alert-icon-wrapper {
    min-width: 20px;
    display: grid;
    place-items: center;
  }
  .label {
    font-weight: 700;
  }
  .time-difference {
    color: ${theme.colors.error};
  }
  @media screen and (max-width: ${theme.breakpoints.sm}) {
    width: 100%;
    justify-content: center;
    padding: 13px 16px;
    border-radius: 12px;
    .colon-span,
    .time-difference {
      display: none;
    }
  }
`
export const EmploymentTransBox = () => {
  // Not Needed in phase 1
  const doNotShow = true
  return !doNotShow ? (
    <InfoBox>
      <div className={"alert-icon-wrapper"}>
        <Image src={"/assets/icons/alert.svg"} height={20} width={20} />
      </div>
      <span className={"label"}>{"Employment transition"}</span>
      <span className={"colon-span"}>{":"}</span>
      <span className={"time-difference"}>
        {" 1 Year : 11 months : 18 days"}
      </span>
    </InfoBox>
  ) : null
}
