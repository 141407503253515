import { Box } from "@project/common"
import { Divider, Flex } from "antd"
import Image from "next/image"
import { useTranslation } from "react-i18next"
import { StyledFooter } from "./ConsumerAuthorizedLayout.styles"
import { AuthContext } from "../../contexts/AuthProvider"
import { useContext, useEffect, useState } from "react"

export const AuthorizedFooter = (): JSX.Element => {
  const { t } = useTranslation()
  const { userInformation } = useContext(AuthContext)
  const [facilityInfo, setFacilityInfo] = useState(null)

  useEffect(() => {
    setFacilityInfo(userInformation?.active_contract?.facility)
  }, [userInformation])

  return (
    <StyledFooter>
      <Box className={"footer-box"} display={"flex"} align={"center"} gap={12}>
        <div className={"footer-box__logo"}>
          <Image
            src={"/assets/imgs/logo-md.png"}
            width={60}
            height={60}
            alt={"footer-logo"}
            className={"logo-img"}
          />
        </div>
        {facilityInfo?.show_my_page_info && (
          <Flex align={"center"} className={"footer-extra-info"}>
            <span>{t(facilityInfo?.facility_name)}</span>
            <Divider orientationMargin={20} type={"vertical"} />
            <span>
              {t(`〒 ${facilityInfo?.contact_po_box} ${facilityInfo?.address}`)}
            </span>
            <Divider orientationMargin={20} type={"vertical"} />
            <span>
              {t(
                `TEL.${facilityInfo?.contact_phone} / FAX.${facilityInfo?.fax}`,
              )}
            </span>
          </Flex>
        )}
      </Box>

      <Box
        display={"flex"}
        direction={"column"}
        align={"center"}
        gap={4}
        ta={"center"}
        className={"footer-right-section"}
      >
        <div className={"footer-right-section__logo"}>
          <Image
            src={"/assets/imgs/logo-md.png"}
            width={60}
            height={60}
            alt={"footer-logo"}
            className={"left-logo-img"}
          />
        </div>
        <span>{t("Copyright © Onelife.Inc")}</span>
      </Box>
    </StyledFooter>
  )
}
