import { t } from "i18next"
import { LockIcon } from "../../public/assets/icons/comp/LockIcon"

export const SettingMenuItem = [
  {
    key: "/update-password",
    label: t("Update Password"),
  },
  {
    key: "logout",
    label: t("Logout"),
    icon: <LockIcon />,
  },
]
