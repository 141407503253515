import { t } from "i18next"
export const PAGE_SIZE = 20
export const PAGE_SIZE_20 = 10
export const FULL_DATE_FORMAT = "YYYY年MM月DD日"
export const FULL_DATE_FORMAT_HM = "YYYY年MM月DD日HH:mm"
export const FULL_DATE_FORMAT_EN = "YYYY-MM-DD"
export const YM_FORMAT = "YYYY年MM月"
export const LEAVE_REQUEST_STATUS = [
  {
    label: "Approved",
    value: 1,
  },
  {
    label: "Waiting Approval",
    value: 2,
  },
  {
    label: "Rejected",
    value: 3,
  },
]

export const REQUEST_STATUS = [
  {
    label: t("Waiting Approval"),
    value: 0,
  },
  {
    label: t("Approved"),
    value: 1,
  },
  {
    label: t("Rejected"),
    value: 2,
  },
]

export const SHORT_MONTH_OPTIONS = [
  {
    label: t("Jan"),
    value: "1",
  },
  {
    label: t("Feb"),
    value: "2",
  },
  {
    label: t("Mar"),
    value: "3",
  },
  {
    label: t("Apr"),
    value: "4",
  },
  {
    label: t("May"),
    value: "5",
  },
  {
    label: t("Jun"),
    value: "6",
  },
  {
    label: t("Jul"),
    value: "7",
  },
  {
    label: t("Aug"),
    value: "8",
  },
  {
    label: t("Sep"),
    value: "9",
  },
  {
    label: t("Oct"),
    value: "10",
  },
  {
    label: t("Nov"),
    value: "11",
  },
  {
    label: t("Dec"),
    value: "12",
  },
]

export const NOT_AVAILABLE_PAGE = [
  "/contact-facility",
  "/service-provision",
  "/individual-support-plan",
  "/individual-support-plan-draft",
  "/monitoring",
  "/resume",
]
export const ACHIEVEMENT_GOAL_TYPE = [
  { label: "---", value: 0 },
  { label: t("achievement"), value: 1 },
  { label: t("Not achieved"), value: 2 },
  { label: t("Partially achieved"), value: 3 },
  { label: t("others"), value: 4 },
]

export const EVALUATION_TYPES = [
  { label: "---", value: 0 },
  { label: t("continuation"), value: 1 },
  { label: t("correction"), value: 2 },
  { label: t("end"), value: 3 },
  { label: t("others"), value: 4 },
]
