import Image from "next/image"
import { useRouter } from "next/router"
import React from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
const Wrapper = styled.div`
  @media print {
    display: none;
  }
  .content {
    display: flex;
    align-items: center;
    gap: 1rem;
    padding-left: 21px;
    cursor: pointer;
    height: 40px;
    background-color: #ca4c30;
    border-radius: 12px;
    margin-top: 32px;
    .title {
      color: #ffffff;
      font-size: 16px;
    }
  }
`
const Notice = () => {
  const { t } = useTranslation()
  const router = useRouter()
  const AlertIcon = (
    <Image src={"/assets/icons/NotificationAlert.svg"} height={24} width={24} />
  )
  return (
    <Wrapper>
      <div
        className={"content"}
        onClick={() => router.push("/facility-application-settings")}
      >
        {AlertIcon}
        <span className={"title"}>{t("May Facility application is open")}</span>
      </div>
    </Wrapper>
  )
}

export { Notice }
