import React, { useEffect, useState } from "react"

// packages
import { t } from "i18next"
import dayjs, { Dayjs } from "dayjs"

// utils
import { keys } from "@project/common"
import { breakTimeInString } from "../../../utils/breakTimeInString"

// styles
import { BreakTimeFlex } from "../ConsumerAuthorizedLayout.styles"

// types
import { BreakStatus, BreaksRecord } from "../types"

const getTotalTime = (
  startDateTime: Dayjs,
  endDateTime: Dayjs,
  extraSeconds?: number,
) => {
  const totalSeconds = endDateTime.diff(startDateTime, "second")

  return breakTimeInString(
    extraSeconds ? totalSeconds + extraSeconds : totalSeconds,
  )
}

export const TimeTracker = ({
  breakStatus,
  breaksRecord,
  date,
}: {
  breakStatus: BreakStatus
  breaksRecord: BreaksRecord
  date: string
}) => {
  const [time, setTime] = useState<string>(
    t("{{hour}}hr {{minute}}mins", {
      hour: "00",
      minute: "00",
    }),
  )

  useEffect(() => {
    const secondsSoFar = keys(breaksRecord).reduce(
      (acc, key) => acc + breaksRecord[key].totalTimeInSeconds,
      0,
    )

    // In case breakStatus is 'tracking' we wanna keep increasing total break time by a minute
    if (breakStatus === "tracking") {
      const startedTime =
        breaksRecord[
          keys(breaksRecord).find(
            (key) => breaksRecord[key].status === "tracking",
          )
        ].start_time
      const startDateTime = dayjs(`${date}${startedTime}`)

      const timeInterval = setInterval(() => {
        const currentDateTime = dayjs()

        setTime(getTotalTime(startDateTime, currentDateTime, secondsSoFar))
      }, 1000)

      return () => clearInterval(timeInterval)
    }

    // In case breakStatus is not 'tracking' we can calculate total breakTime taken in each breaks i.e. "break_time_1", "break_time_2"
    // and display them like so.
    setTime(breakTimeInString(secondsSoFar))
  }, [breaksRecord])

  return (
    <BreakTimeFlex gap={8} align={"center"}>
      <p className={"label"}>
        {t("Break time")}
        {":"}
      </p>
      <span className={"value"}>{time}</span>
    </BreakTimeFlex>
  )
}
