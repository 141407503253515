import { useRouter } from "next/router"
import React, { useState } from "react"

// packages
import { t } from "i18next"

// commons | components
import { Box } from "@project/common"

// styles
import { StyledTabs } from "./NavigationTabs.styles"

// constants
import { consumerMenuItems } from "./constants"

// utils functions
const getProperPathname = (pathname: string) => {
  const baseRoute = pathname.split("/")[1]
  if (baseRoute !== "/") {
    return `/${baseRoute}`
  }
  return "/"
}

export const NavigationTabs: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const router = useRouter()

  const [activeLink, setActiveLink] = useState(
    getProperPathname(router.pathname),
  )

  return (
    <StyledTabs
      className={"navigation-tabs"}
      defaultActiveKey={consumerMenuItems[0].link}
      activeKey={activeLink}
      onChange={(link) => {
        setActiveLink(link)
        router.push(link)
      }}
      type={"card"}
      size={"large"}
      animated
      items={consumerMenuItems.map((item) => {
        return {
          label: (
            <Box
              display={"flex"}
              direction={"column"}
              justify={"center"}
              ta={"center"}
              align={"center"}
              gap={5}
              h={"100%"}
            >
              <div className={"nav-item-icon"}>{item.icon}</div>
              <span className={"tab-label"} style={{ flex: 1 }}>
                {t(item.label)}
              </span>
            </Box>
          ),
          key: item.link,
          children: children,
        }
      })}
    />
  )
}
