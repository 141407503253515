// packages
import styled from "styled-components"

// commons
import { Box, theme } from "@project/common"

export const StyledConsumerBody = styled.main`
  display: grid;
  place-items: center;
  background-color: ${theme.colors.blueShade};
  min-height: calc(100vh - 137px);
  padding: 36px 20px;
`

export const StyledFooter = styled(Box)`
  a {
    font-size: 16px;
    font-weight: 500;
  }
  .copyright-text {
    font-size: 12px;
    font-weight: normal;
  }
`
