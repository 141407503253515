import Image from "next/image"
import React from "react"

// packages
import { t } from "i18next"

// commons | components
import { Box } from "@project/common"

// styles
import { StyledConsumerBody, StyledFooter } from "./ConsumerUnauthorized.styles"

export const ConsumerLayout: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <>
      {/* Header starts here */}
      <Box
        py={12}
        gap={20}
        fw={500}
        fz={"lg"}
        c={"white"}
        bg={"secondary"}
        display={"flex"}
        align={"center"}
        px={{ "0px": "20px", md: "36px" }}
      >
        <Image
          src={"/assets/imgs/logo-sm.png"}
          width={33}
          height={33}
          alt={"logo"}
        />
        {t("Employment support")}
      </Box>
      {/* Header ends here */}

      <StyledConsumerBody>{children}</StyledConsumerBody>

      {/* Footer starts here */}
      <StyledFooter
        py={28}
        mih={80}
        gap={20}
        fw={500}
        fz={"md"}
        c={"text"}
        wrap={"wrap"}
        display={"flex"}
        align={"center"}
        justify={"space-between"}
        px={{ "0px": "20px", md: "36px" }}
      >
        <div></div>
        <p className={"copyright-text"}>{t("Copyright © Onelife.Inc")}</p>
      </StyledFooter>
      {/* Footer ends here */}
    </>
  )
}
