import React from "react"

export const PaperIcon = () => {
  return (
    <svg
      width={"46"}
      height={"45"}
      viewBox={"0 0 46 45"}
      fill={"none"}
      xmlns={"http://www.w3.org/2000/svg"}
    >
      <circle
        cx={"23.4443"}
        cy={"22.5"}
        r={"22.5"}
        fill={"currentColor"}
        fillOpacity={"0.6"}
      />
      <rect
        x={"13.9443"}
        y={"15.5"}
        width={"17.25"}
        height={"5.25"}
        fill={"#FDD95F"}
      />
      <path
        d={
          "M18.9433 15.9863H14.9423C14.8104 15.9863 14.6799 16.0125 14.5582 16.0632C14.4365 16.114 14.3261 16.1884 14.2333 16.2821C14.1405 16.3758 14.0672 16.487 14.0176 16.6092C13.968 16.7314 13.9431 16.8623 13.9444 16.9942V30.991C13.9431 31.123 13.968 31.2538 14.0176 31.376C14.0672 31.4983 14.1405 31.6094 14.2333 31.7031C14.3261 31.7969 14.4365 31.8712 14.5582 31.922C14.6799 31.9728 14.8104 31.9989 14.9423 31.9989H30.9464C31.2103 31.9964 31.4627 31.8905 31.6493 31.7037C31.8359 31.517 31.9418 31.2645 31.9443 31.0005V17.0036C31.9455 16.8705 31.9204 16.7384 31.8703 16.615C31.8203 16.4916 31.7463 16.3794 31.6526 16.2848C31.5589 16.1903 31.4475 16.1152 31.3246 16.0639C31.2018 16.0127 31.0701 15.9863 30.937 15.9863H29.9297"
        }
        stroke={"#191919"}
        strokeWidth={"1.5"}
        strokeLinecap={"round"}
        strokeLinejoin={"round"}
      />
      <path
        d={"M18.9434 14V18.0031"}
        stroke={"#191919"}
        strokeWidth={"1.5"}
        strokeLinecap={"round"}
        strokeLinejoin={"round"}
      />
      <path
        d={"M26.936 14V18.0031"}
        stroke={"#191919"}
        strokeWidth={"1.5"}
        strokeLinecap={"round"}
        strokeLinejoin={"round"}
      />
      <path
        d={"M21.937 15.9961H26.9359"}
        stroke={"#191919"}
        strokeWidth={"1.5"}
        strokeLinecap={"round"}
        strokeLinejoin={"round"}
      />
      <path
        d={"M20.6377 25.4162L22.2193 26.9986L24.7517 24.4648"}
        stroke={"#191919"}
        strokeWidth={"1.5"}
        strokeLinecap={"round"}
        strokeLinejoin={"round"}
      />
      <path
        d={"M14.3398 20.9141H29.4025"}
        stroke={"#191919"}
        strokeWidth={"1.5"}
        strokeLinecap={"round"}
        strokeLinejoin={"round"}
      />
    </svg>
  )
}
