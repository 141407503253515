import { useRouter } from "next/router"
import React from "react"

// packages
import { Button, Flex, Layout } from "antd"
import dayjs from "dayjs"
import { t } from "i18next"
import { useMutation, useQueryClient } from "react-query"

// commons | components
import {
  Box,
  dynamicLangString,
  FeatureNotAvailableInternal,
  getUserAttendanceTypesByService,
  useNotification,
  USER_ATTENDANCE_VALUES,
  useUpdateSearchParams,
} from "@project/common"
import { EmploymentTransBox } from "../EmploymentTransBox"
import { NavigationTabs } from "../NavigationTabs"
import { Notice } from "../Notice/Notice"
import { AuthorizedFooter } from "./AuthorizedFooter"
import { AuthorizedHeader } from "./AuthorizedHeader"
import { TimeTracker } from "./components/TimeTracker"

// styles
import {
  StyledContent,
  TimeTrackBtnGroup,
} from "./ConsumerAuthorizedLayout.styles"

// assets
import { EditOutlined } from "@ant-design/icons"
import { HotCoffee } from "../../public/assets/icons/comp"

// services
import { DailyAttendanceData, updateAttendance } from "../../services"

// types
import { NOT_AVAILABLE_PAGE } from "../../constants"
import { ConsumerAuthorizedLayoutProps } from "./types"

//  utility function
const isUserAbsent = (type) =>
  type === USER_ATTENDANCE_VALUES.ABSENCE ||
  type === USER_ATTENDANCE_VALUES.ABSENCE_NO_ADDITION

export const ConsumerAuthorizedLayout: React.FC<
  ConsumerAuthorizedLayoutProps
> = ({
  user,
  children,
  currenDate,
  checkInTime,
  isScheduled,
  breakStatus,
  checkOutTime,
  attendanceType,
  additionalProps,
  attendanceStarted,
  attendanceCompleted,
  breaksRecord,
  serviceType = 0,
}) => {
  const router = useRouter()
  const queryClient = useQueryClient()
  const { showToast } = useNotification()
  const [updateParams] = useUpdateSearchParams()
  // ! ## React query/mutations
  // 1. Updates attendance info [mutation]
  const {
    mutate: editAttendance,
    isLoading: isUpdatingAttendance,
    variables,
  } = useMutation({
    mutationFn: updateAttendance,
    onSuccess: async (_, variables) => {
      showToast({
        type: "success",
        message: dynamicLangString([
          t("Attendance"),
          t("Updated Successfully"),
        ]),
      })

      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: ["attendance-context"] })

      // Snapshot the previous value
      const prevAttendanceData = queryClient.getQueryData<DailyAttendanceData>([
        "attendance-context",
      ])

      // Generate new data based on `prevData` and `variables`
      let attendance_data = prevAttendanceData.data.attendance_data

      switch (variables.action) {
        case "checkin": {
          attendance_data = {
            ...prevAttendanceData.data.attendance_data,
            start_time: variables.time,
          }
          return
        }
        case "checkout": {
          attendance_data = {
            ...prevAttendanceData.data.attendance_data,
            end_time: variables.time,
          }
          return
        }
        case "break_start": {
          attendance_data = {
            ...prevAttendanceData.data.attendance_data,
            break_start_time: variables.time,
          }
          return
        }

        case "break_end": {
          attendance_data = {
            ...prevAttendanceData.data.attendance_data,
            break_end_time: variables.time,
          }
          return
        }
      }

      const newData = {
        data: {
          ...prevAttendanceData.data,
          attendance_data: {
            ...attendance_data,
          },
        },
      }

      // Optimistically update to the new value
      queryClient.setQueryData<DailyAttendanceData>(
        ["attendance-context"],
        () => newData,
      )
    },
    onError: () => {
      showToast({
        type: "error",
        message: t("Something went wrong. Please contact administrator."),
      })
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ["attendance-context"] })
      queryClient.invalidateQueries({
        queryKey: ["all-attendance-record-my-month"],
      })
    },
  })

  // ! ## All Event handlings
  // 1. Handles attendance based off of attendance status
  const onTimeTrackButton = () => {
    const currenTime = dayjs().format("HH:mm")

    if (!attendanceStarted) {
      editAttendance({
        action: "checkin",
        date: currenDate,
        time: currenTime,
      })
      return
    }

    if (attendanceStarted && !attendanceCompleted) {
      editAttendance({
        action: "checkout",
        date: currenDate,
        time: currenTime,
      })
    }
  }

  // 2. handles break time based off of break status
  const handleBreakTime = () => {
    const currentTime = dayjs().format("HH:mm")

    switch (breakStatus) {
      case "not-started": {
        editAttendance({
          action: "break_start",
          date: currenDate,
          time: currentTime,
        })
        return
      }
      case "tracking": {
        editAttendance({
          action: "break_end",
          date: currenDate,
          time: currentTime,
        })
      }
    }
  }

  return (
    <Layout>
      {/* Header starts here */}
      <AuthorizedHeader user={user} />
      {/* Header ends here */}

      {/* content starts here */}
      <StyledContent>
        {!additionalProps?.hideTabs ? (
          <>
            <Box
              display={"flex"}
              align={"center"}
              gap={"16px 39px"}
              wrap={"wrap"}
              justify={"space-between"}
              direction={{
                "0px": "column",
                md: "row",
              }}
            >
              <Flex
                className={"attendance-action"}
                align={"center"}
                wrap={"wrap"}
                gap={20}
              >
                <TimeTrackBtnGroup gap={20} align={"center"}>
                  {/* Attendance handling time button */}
                  <Box
                    w={{
                      "0px": !attendanceStarted ? "100%" : "151px",
                      md: "250px",
                    }}
                    className={"attendance-btn-box"}
                  >
                    <Button
                      block
                      size={"large"}
                      type={"primary"}
                      disabled={!isScheduled || isUserAbsent(attendanceType)}
                      className={`attendance-button ${
                        attendanceStarted && !attendanceCompleted
                          ? "tracking-time"
                          : !attendanceStarted
                            ? "tracking-pending"
                            : "tracking-time-completed"
                      }`}
                      onClick={onTimeTrackButton}
                      loading={
                        isUpdatingAttendance &&
                        (variables?.action === "checkin" ||
                          variables?.action === "checkout" ||
                          variables?.action === "absent")
                      }
                    >
                      {isUserAbsent(attendanceType)
                        ? t("Absent")
                        : attendanceStarted && !attendanceCompleted
                          ? t("Leave")
                          : !attendanceStarted
                            ? t("Attend")
                            : t(
                                getUserAttendanceTypesByService(
                                  serviceType,
                                )?.find(
                                  (attendance) =>
                                    attendance?.value == attendanceType,
                                )?.label || "",
                              )}
                    </Button>
                  </Box>

                  {/* Break handling time button */}
                  {attendanceStarted && breakStatus !== "full-occupancy" && (
                    <Button
                      className={`break-time-btn btn-${breakStatus}`}
                      onClick={() => handleBreakTime()}
                      loading={
                        isUpdatingAttendance &&
                        (variables?.action === "break_start" ||
                          variables?.action === "break_end")
                      }
                    >
                      <Box
                        display={"flex"}
                        fz={22}
                        align={"center"}
                        gap={8}
                        justify={"center"}
                        className={"btn-content"}
                      >
                        <span>
                          {breakStatus === "not-started" && t("start break")}
                          {breakStatus === "tracking" && t("End break")}
                        </span>
                        <div className={`coffee-icon ${breakStatus}`}>
                          <div className={"steam"}></div>
                          <HotCoffee />
                        </div>
                      </Box>
                    </Button>
                  )}
                </TimeTrackBtnGroup>

                {/* Attendance time range */}
                <p className={"time-cycle-text"}>
                  {isScheduled &&
                  !isUserAbsent(attendanceType) &&
                  (attendanceStarted || attendanceCompleted)
                    ? "出勤時刻："
                    : ""}
                  {attendanceStarted && `${checkInTime} ~ `}
                  {attendanceCompleted && checkOutTime}
                </p>

                {/* Attendance edit button starts here */}
                {!additionalProps?.hideTransition && (
                  <>
                    {!attendanceStarted ? (
                      <EmploymentTransBox />
                    ) : (
                      <Button
                        size={"large"}
                        icon={<EditOutlined />}
                        shape={"round"}
                        onClick={() => {
                          updateParams(
                            {
                              ...router.query,
                              date: dayjs(currenDate).format("YYYY-MM"),
                              daily_record: "calender",
                              edit_request_from: "main_controller",
                            },
                            "/",
                          )
                        }}
                      >
                        {t("Attendance Edit Request")}
                      </Button>
                    )}
                  </>
                )}
              </Flex>

              {/* total break time in HH:mm */}
              {!attendanceStarted || breakStatus === "full-occupancy" ? null : (
                <TimeTracker
                  date={currenDate}
                  breakStatus={breakStatus}
                  breaksRecord={breaksRecord}
                />
              )}
            </Box>
            {router.pathname == "/service-provision" && <Notice />}
            <h4 className={"page-title"}>
              {t(additionalProps?.pageTitle || "Daily work related ")}
            </h4>
            <NavigationTabs>
              {NOT_AVAILABLE_PAGE?.includes(router.pathname) ? (
                <FeatureNotAvailableInternal goto={router.pathname} />
              ) : (
                <>{children}</>
              )}
            </NavigationTabs>
          </>
        ) : (
          children
        )}
      </StyledContent>
      {/* content ends here */}

      {/* Footer starts here */}
      <AuthorizedFooter />
      {/* Footer ends here */}
    </Layout>
  )
}
