import React from "react"
import Link from "next/link"
import { useRouter } from "next/router"

// packages
import { t } from "i18next"
import { Menu } from "antd"
import Image from "next/image"
import { MenuProps } from "antd/lib"
import { signOut } from "firebase/auth"

// common | components
import { Box, auth } from "@project/common"

// styles
import {
  HeaderLogo,
  StyledHeader,
  SettingPopover,
} from "./ConsumerAuthorizedLayout.styles"

// assets
import { SettingIcon } from "../../public/assets/icons/comp"

// constants
import { SettingMenuItem } from "./constants"

// types
import { Me } from "../../types"

export const AuthorizedHeader = ({ user }: { user: Me }): JSX.Element => {
  const { push, pathname } = useRouter()
  const [open, setOpen] = React.useState(false)

  const logOut = () => {
    localStorage.clear()
    signOut(auth)
    window.location.href = "/login"
  }

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen)
  }

  const handleMenuItemClick: MenuProps["onClick"] = (e) => {
    if (e.key === "logout") {
      logOut()
      return
    }
    push(e.key)
    handleOpenChange(false)
  }

  return (
    <StyledHeader>
      <HeaderLogo
        top={0}
        py={12}
        left={0}
        gap={12}
        bg={"cyan2"}
        lh={"23.17px"}
        display={"flex"}
        align={"center"}
        pos={"absolute"}
        px={{ "0px": "16px", md: "20px" }}
        h={{
          "0px": "calc(100% + 10px)",
          md: "calc(100% + 15px)",
        }}
      >
        <Link href={"/"} className={"home-link"}>
          <a>
            <Image
              src={"/assets/imgs/logo-md.png"}
              height={60}
              width={60}
              alt={"logo"}
              className={"logo"}
            />
          </a>
        </Link>
        <Link href={"/"} className={"home-link"}>
          <a>
            <p className={"name-text"}>
              {user?.user?.username ? `${user?.user?.username}さん` : ""}
              <br />
              <span className={"sub-text"}>{"専用ページ"}</span>
            </p>
          </a>
        </Link>
      </HeaderLogo>

      <SettingPopover
        className={"setting-popover"}
        arrow={false}
        getPopupContainer={(triggerNode) => triggerNode}
        placement={"bottomRight"}
        title={
          <Box
            px={20}
            h={54}
            display={"flex"}
            align={"center"}
            gap={8}
            bg={"secondary"}
            c={"white"}
            fw={500}
          >
            <SettingIcon />
            <span>{t("User settings menu")}</span>
          </Box>
        }
        content={
          <Menu
            onClick={handleMenuItemClick}
            mode={"vertical"}
            className={"setting-menu"}
            items={SettingMenuItem}
            selectedKeys={[pathname]}
          />
        }
        trigger={"click"}
        open={open}
        onOpenChange={handleOpenChange}
      >
        <button className={"setting-button"}>
          <span>
            <SettingIcon />
          </span>
          <span className={"setting-btn-label"}>{"設定"}</span>
        </button>
      </SettingPopover>
    </StyledHeader>
  )
}
