import { theme } from "@project/common"
import { Tabs, TabsProps } from "antd"
import React from "react"
import styled from "styled-components"

const StyledTabsV2 = styled(Tabs)`
  .ant-tabs-nav {
    .ant-tabs-nav-wrap .ant-tabs-nav-list {
      .ant-tabs-tab {
        width: 280px;
        max-width: 280px;
        min-width: 95.67px;
        padding: 9px 20px;
        border-color: ${theme.colors.softGray1};
        background-color: ${theme.colors.softGray1};
        .ant-tabs-tab-btn {
          color: ${theme.colors.gray10};
          width: 100%;
          white-space: normal;
          font-size: 18px;
          font-weight: 500;
        }

        &.ant-tabs-tab-active {
          background-color: ${theme.colors.cyan5};
          border: 1px solid ${theme.colors.border3};
          border-bottom: none;
          .ant-tabs-tab-btn {
            color: ${theme.colors.text};
            font-weight: 500;
          }

          &::after {
            background-color: ${theme.colors.border3};
          }
        }

        &::after {
          background-color: ${theme.colors.border3};
        }
      }

      @media screen and (max-width: 500px) {
        .ant-tabs-tab {
          width: 95.67px;
          padding: 15px 8px;
        }
        .ant-tabs-tab-btn {
          font-size: 14px !important;
        }
        .ant-tabs-tab-active {
          font-size: 14px !important;
        }
      }
    }
    &::before {
      border-color: ${theme.colors.border3};
    }
  }

  .ant-tabs-content-holder {
    border-color: ${theme.colors.border3};
    padding: 20px 30px 30px 30px;
    @media screen and (max-width: ${theme.breakpoints.lg}) {
      padding: 12px !important;
    }
    @media screen and (max-width: ${theme.breakpoints.md}) {
      padding: 8px !important;
    }
  }
`

export const TabsV2: React.FC<TabsProps> = ({
  size = "large",
  type = "card",
  ...props
}) => {
  return <StyledTabsV2 type={type} size={size} {...props} />
}
