import React from "react"

const HotCoffee = () => {
  return (
    <svg
      width={"28"}
      height={"28"}
      viewBox={"0 0 28 28"}
      fill={"none"}
      xmlns={"http://www.w3.org/2000/svg"}
    >
      <g clipPath={"url(#clip0_2098_49353)"}>
        <path
          d={
            "M20.9961 9.33337H22.1628C23.4004 9.33337 24.5874 9.82504 25.4626 10.7002C26.3378 11.5754 26.8294 12.7624 26.8294 14C26.8294 15.2377 26.3378 16.4247 25.4626 17.2999C24.5874 18.175 23.4004 18.6667 22.1628 18.6667H20.9961"
          }
          stroke={"currentColor"}
          strokeWidth={"2"}
          strokeLinecap={"round"}
          strokeLinejoin={"round"}
        />
        <path
          d={
            "M2.32959 9.33337H20.9963V19.8334C20.9963 21.0711 20.5046 22.258 19.6294 23.1332C18.7543 24.0084 17.5673 24.5 16.3296 24.5H6.99626C5.75858 24.5 4.57159 24.0084 3.69642 23.1332C2.82126 22.258 2.32959 21.0711 2.32959 19.8334V9.33337Z"
          }
          stroke={"currentColor"}
          strokeWidth={"2"}
          strokeLinecap={"round"}
          strokeLinejoin={"round"}
        />
        {/* <path
          d={"M6.99609 3V5"}
          stroke={"currentColor"}
          strokeWidth={"2"}
          strokeLinecap={"round"}
          strokeLinejoin={"round"}
        />
        <path
          d={"M11.6626 1.16663V4.66663"}
          stroke={"currentColor"}
          strokeWidth={"2"}
          strokeLinecap={"round"}
          strokeLinejoin={"round"}
        />
        <path
          d={"M15.9961 2L15.9961 4"}
          stroke={"currentColor"}
          strokeWidth={"2"}
          strokeLinecap={"round"}
          strokeLinejoin={"round"}
        /> */}
      </g>
      <defs>
        <clipPath id={"clip0_2098_49353"}>
          <rect
            width={"28"}
            height={"28"}
            fill={"white"}
            transform={"translate(-0.00390625)"}
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export { HotCoffee }
